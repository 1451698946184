<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="switch"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>
    <v-col cols="12" class="pb-2">
      <h3>Select Query to edit steps</h3>
    </v-col>
  </v-row>
</template>

<script>
import { ScriptEnginesSelect } from './components'
import StepMixin from './StepMixin'

export default {
  name: 'SwitchStep',
  components: {
    ScriptEnginesSelect
  },
  mixins: [StepMixin]
}
</script>
